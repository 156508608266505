import { CommonModule, Location } from '@angular/common';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { UikitMessages } from '../uikit-messages/index.component';
import packageJson from '../../../../../package.json';
import { Router, RouterLink } from '@angular/router';

@Component({
    selector: 'uikit-page',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.scss'],
    standalone: true,
    imports: [CommonModule, UikitMessages, RouterLink],
})
export class UikitPage implements OnInit {
    public appJson: any = packageJson;

    constructor(private elementRef: ElementRef, private router: Router) { }

    @Input() showBack: boolean = true;
    @Input() loading: boolean = false;
    @Input() loadingMessage: string = '';
    @Input() paths: { route: string; title: string }[] = [];
    @Input() errorMessage: string = '';
    @Input() title: string = '';
    @Input() onClickPromise: () => Promise<void> | undefined;
    @Output() onClick = new EventEmitter<any>();

    get canShowContent() {
        if (
            this.loading ||
            this.loadingMessage != '' ||
            this.errorMessage != ''
        )
            return false;
        return true;
    }

    get isLoading() {
        if (this.loadingMessage != '' || this.loading) return true;
        return false;
    }

    async goBack() {
        if (this.paths != null) {
            if (this.paths.length > 0) {
                let nextRoute = this.paths[this.paths.length - 1].route;
                if (nextRoute == location.pathname) {
                    if (this.paths.length > 1) {
                        nextRoute = this.paths[this.paths.length - 2].route;
                    } else nextRoute = '/';
                }
                this.router.navigateByUrl(nextRoute);
            } else {
                this.router.navigateByUrl('/');
            }
        } else {
            history.back();
        }
    }

    ngOnInit(): void {
        this.elementRef.nativeElement.title = '';
    }
}

import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
    HttpParams,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable()
export class ApiService {
    constructor(public http: HttpClient) { }

    // Headers
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };


    removeNullValuesFromQueryParams(params: HttpParams) {
        const paramsKeysAux = params.keys();
        paramsKeysAux.forEach((key) => {
            const value = params.get(key);
            if (
                value === 'null' ||
                value === 'undefined' ||
                value === '' ||
                value === null ||
                value === undefined
            ) {
                params['map'].delete(key);
            }
        });

        return params;
    }

    // Manipulação de err
    handleError(error: HttpErrorResponse) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Erro ocorreu no lado do client
            errorMessage = error.error.message;
        } else {
            // Erro ocorreu no lado do servidor
            errorMessage =
                `Código do erro: ${error.status}, ` +
                `menssagem: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }


    generatePromise<T>(observable: Observable<T>): Promise<T> {
        return new Promise((accept, reject) => {
            observable.subscribe({
                next: (d) => accept(d), error: (e) => reject(e)
            })
        })
    }

    async postPromise<T>(url: string, body: any): Promise<T> {
        const obs = this.http
            .post<T>(
                `${environment.apiUrl}${url}`, body
            )
        return await this.generatePromise(obs)
    }
    async getPromise<T>(url: string, params?: any, props?: any): Promise<T> {
        const obs: Observable<any> = this.http
            .get(
                `${environment.apiUrl}${url}`, { params: params, ...props ?? {} }
            )
        return await this.generatePromise<T>(obs)
    }
    async deletePromise<T>(url: string): Promise<T> {
        const obs = this.http
            .delete<T>(
                `${environment.apiUrl}${url}`
            );

        return await this.generatePromise(obs)
    }
}

import { Injectable } from '@angular/core';
import { Observable, catchError, retry, throwError } from 'rxjs';
import { Vehicle } from '../models/vehicle.model';
import { environment } from 'src/environments/environment';
import { VehiclePagedList } from '../models/pagedList.model';
import { OrderStatus } from '../models/OrderStatus.model';
import { OrderTrackingDetailLog } from '../models/vehicleDetail.model';
import { LocationFilter, VehicleLocationTotalDTO } from '../models/locations.model';
import { OrderSalesTypes } from '../models/OrderSalesType.model';
import { ApiService } from './api.service';
import { VehicleStatusLogEvents } from '../models/vehicleStatusLogEvents.model';

@Injectable()
export class VehicleService extends ApiService {


    getVehiclesData() {
        return [
            {
                id: '1000',
                modelo: 'I/VW TIGUAN ALLSPACE CL',
                chassi: '9532VWBR00A114822',
                numeroPedido: '987654321',
                numeroNF: '52114822',
                dataPedido: '22/02/2024',
                status: 'EM ESTOQUE',
            },
            {
                id: '1001',
                modelo: 'I/VW TIGUAN ALLSPACE CL',
                chassi: '9532VWBR00A114822',
                numeroPedido: '987654321',
                numeroNF: '52114822',
                dataPedido: '22/02/2024',
                status: 'EM ESTOQUE',
            },
            {
                id: '1002',
                modelo: 'VW - VIRTUS',
                chassi: '9532VWBR00A114822',
                numeroPedido: '987654321',
                numeroNF: '52114822',
                dataPedido: '22/02/2024',
                status: 'DEVOLUCAO',
            },
            {
                id: '1003',
                modelo: 'I/VW TIGUAN ALLSPACE CL',
                chassi: '9532VWBR00A114822',
                numeroPedido: '987654321',
                numeroNF: '52114822',
                dataPedido: '22/02/2024',
                status: 'FATURADO',
            },
            {
                id: '1004',
                modelo: 'VW - VIRTUS',
                chassi: '9532VWBR00A114822',
                numeroPedido: '987654321',
                numeroNF: '52114822',
                dataPedido: '22/02/2024',
                status: 'EM ESTOQUE',
            },
            {
                id: '1005',
                modelo: 'I/VW TIGUAN ALLSPACE CL',
                chassi: '9532VWBR00A114822',
                numeroPedido: '987654321',
                numeroNF: '52114822',
                dataPedido: '22/02/2024',
                status: 'TRANSITO',
            },
            {
                id: '1006',
                modelo: 'VW - VIRTUS',
                chassi: '9532VWBR00A114822',
                numeroPedido: '987654321',
                numeroNF: '52114822',
                dataPedido: '22/02/2024',
                status: 'EM ESTOQUE',
            },
        ];
    }

    getVehiclesMini() {
        return Promise.resolve(this.getVehiclesData().slice(0, 5));
    }

    getVehiclesSmall() {
        return Promise.resolve(this.getVehiclesData().slice(0, 10));
    }

    getVehicles() {
        return Promise.resolve(this.getVehiclesData());
    }

    getVehicleByChassiMock(chassi: string) {
        return Promise.resolve(
            this.getVehiclesData().find((x) => x.chassi === chassi)
        );
    }

    getVehicleByChassi(chassi: string): Observable<Vehicle> {
        return this.http
            .get<Vehicle>(
                `${environment.apiUrl}/vehicle/details/chassis/${chassi}`
            )
            .pipe(retry(1), catchError(this.handleError));
    }

    getVehicleById(id: string): Observable<Vehicle> {
        return this.http
            .get<Vehicle>(`${environment.apiUrl}/vehicle/details/${id}`)
            .pipe(retry(1), catchError(this.handleError));
    }

    getVehicleOrderStatusEvents(orderId: number, orderItemId: number): Observable<VehicleStatusLogEvents[]> {
        return this.http
            .get<VehicleStatusLogEvents[]>(
                `${environment.apiUrl}/vehicle/listVehicleStatusEvents?orderId=${orderId}&orderItemId=${orderItemId}`)
            .pipe(retry(0), catchError(this.handleError));
    }

    getVehicleOrderStatus(): Observable<OrderStatus[]> {
        return this.http
            .get<OrderStatus[]>(`${environment.apiUrl}/vehicle/order-status`)
            .pipe(retry(0), catchError(this.handleError));
    }

    getPagedVehicles(
        paramsFilter: any
    ): Observable<VehiclePagedList> {
        const url = `${environment.apiUrl}/vehicle/list`;

        return this.http
            .post<VehiclePagedList>(url, paramsFilter)
            .pipe(retry(0), catchError(this.handleError));
    }

    getOrderTrackingLog(paramsBody: {
        orderId: number;
        orderItemId: number;
    }): Observable<OrderTrackingDetailLog> {
        return this.http
            .post<OrderTrackingDetailLog>(
                `${environment.apiUrl}/trackingTransportation/getOrderItemsLocation`,
                paramsBody
            )
            .pipe(retry(0), catchError(this.handleError));
    }

    getVehicleLocations(
        paramsFilter: LocationFilter
    ): Observable<VehicleLocationTotalDTO> {
        const url = `${environment.apiUrl}/vehicle/listVehicleLocations`;

        return this.http
            .post<VehicleLocationTotalDTO>(url, paramsFilter)
            .pipe(retry(0), catchError(this.handleError));
    }

    generateVehiclesReport(paramsFilter: any): Observable<Blob> {
        const url = `${environment.apiUrl}/vehicle/report`;

        return this.http
            .post(url, paramsFilter, {
                responseType: 'blob',
            })
            .pipe(retry(0), catchError(this.handleError));
    }

    getRegions(): Observable<string> {
        return this.http
            .get<string>(`${environment.apiUrl}/division/listRegions`)
            .pipe(retry(0), catchError(this.handleError));
    }

    getOrderSalesType(): Observable<OrderSalesTypes> {
        return this.http
            .get<OrderSalesTypes>(
                `${environment.apiUrl}/vehicle/listSalesTypes`
            )
            .pipe(retry(0), catchError(this.handleError));
    }

}
